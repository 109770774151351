@import url(https://fonts.googleapis.com/css?family=Heebo:400,500,700,800&display=swap);
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  font-family: 'Montserrat', sans-serif;
  color: #3E3E3E;
  padding: 0;
}

button {
  padding: 0;
	cursor: pointer;
	text-decoration: none;
	border: none;
	outline: none;
	-webkit-tap-highlight-color: transparent;
}

p {
  margin: 0;
}

/* @import url('https://fonts.googleapis.com/css?family=Montserrat:500,600,700,800&display=swap'); */
/* @import url('https://fonts.googleapis.com/css?family=Raleway:500,600,700,800&display=swap'); */

.App {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.App p {
  font-weight: 500;
  font-size: 16px;
  line-height: 1.5;
}

.App a {
  color: #4281CA;
  text-decoration: none;
}

.App a :active {
  color: #4281CA;
  text-decoration: none;
}

.App .header-container {
  width: 100vw;
  height: -webkit-max-content;
  height: -moz-max-content;
  height: max-content;
  position: relative;
}

.App .header-container img {
  width: 100vw;
  max-height: 900px;
  object-fit: cover;
}

.App .header-wrapper {
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}


.App .header-container h1 {
  margin: 0;
  font-weight: 700;
  font-size: 42px;
  color: #FFFFFF;
  letter-spacing: 5px;
  text-align: center;
}

.App .header-container h2 {
  margin: 9px 0;
  font-weight: 600;
  font-size: 23px;
  color: #FFFFFF;
  letter-spacing: 1px;
  text-align: left;
}

.App .wrapper {
  box-sizing: border-box;
  width: 100%;
  max-width: 1035px;
  padding: 0 25px;
  margin-top: 75px;
  display: flex;
  flex-direction: column;
  align-items: flex;
}

.App .text-1 {
  font-weight: 600;
}

.App .text-2 {
  font-weight: 600;
  margin-top: 18px;
}


.App .text-3 {
  font-weight: 700;
  margin-top: 75px;
  font-size: 23px;
}

.App span {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 10px;
  height: -webkit-max-content;
  height: -moz-max-content;
  height: max-content;
}

.App .point {
  min-width: 5px;
  width: 5px;
  height: 5px;
  min-height: 5px;
  background-color: #3E3E3E;
  margin-right: 14px;
}

.App .aufzählung span:nth-child(2){ 
  margin-top: 6px;
  margin-left: 31px; 
}
.App .aufzählung span:nth-child(3){ 
  margin-top: 4px; 
  margin-left: 31px;
}

.App .aufzählung span:nth-child(2) p { font-size: 14px;}
.App .aufzählung span:nth-child(3) p { font-size: 14px;}

.App .text-4 {
  font-weight: 700;
  margin-top: 75px;
  font-size: 23px;
}

.App .text-5 {
  margin-top: 18px;
}

/****** Aufzählung 2 */
.App .aufzählung-2 span { margin-top: 5px;}
.App .aufzählung-2 span:nth-child(1){ margin-top: 18px; }

.App .text-6 {
  font-weight: 600;
  margin-top: 38px;
  font-size: 19px;
}

.App .zumVPN {
  margin-top: 26px;
  width: 201px;
  height: 39px;
  background-image: linear-gradient(-135deg, #8AC8FE 0%, #68AAFF 100%);
  font-weight: 700;
  font-size: 13px;
  color: #FFFFFF;
  letter-spacing: 1px;
}

/*****  */
.App .text-7 {
  font-weight: 700;
  margin-top: 75px;
  font-size: 23px;
}

.App .text-8 {
  margin-top: 25px;
}

.App .text-9 {
  margin-top: 10px;
}

.App .text-10 {
  margin-left: 32px;
  margin-top: 38px;
  font-weight: 700;
}

.App .text-11 {
  margin-left: 32px;
  margin-top: 50px;
  font-weight: 700;
}

.App iframe {
  box-sizing: border-box;
  padding-left: 32px;
  margin-top: 18px;
  max-width: 806px;
  max-height: 453.375px;
  width: 60vw;
  height: 32vw;
}

.App .text-12 {
  margin-top: 50px;
}

.App .text-13 {
  margin-left: 32px;
  margin-top: 30px;
  font-weight: 700;
}

.App .img {
  object-fit: cover;
  height: -webkit-max-content;
  height: -moz-max-content;
  height: max-content;
}
.App .img-mac-1 {
  max-width: 806px;
  width: 100%;
}

.App .text-14 {
  margin-left: 32px;
  margin-top: 4px;
}

.App .img-mac-2 {
  margin-top: 44px;
  max-width: 856px;
  width: 100%;
}

.App .text-15 {
  margin-left: 32px;
  margin-top: 4px;
}

.App .text-16 {
  margin-left: 32px;
  margin-top: 5px;
}

.App .text-17 {
  margin-left: 32px;
  margin-top: 79px;
  font-weight: 700;
}

.App .text-18 {
  margin-left: 32px;
  margin-top: 29px;
}
.App .text-19 {
  margin-left: 32px;
  margin-top: 13px;
}
.App .text-20 {
  margin-left: 32px;
  margin-top: 13px;
}
.App .text-21 {
  margin-left: 32px;
  margin-top: 13px;
}

.App .last {
  margin-top: 80px;
}


.App .endContainer {
  margin-top: 100px;
  margin-bottom: 30px;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.App .endContainer a {
  color: #3E3E3E;
  font-size: 13px;
  font-weight: 500;
}

.App .langContainer {
  width: 46px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.App .langContainer button {
  font-size: 13px;
  text-decoration: none;
  background-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.App .langContainer button :active {
  color: #3E3E3E;
}



.toMainLink :active {
  color: #3E3E3E;
}

/* phone */
@media only screen and (max-width: 974px) {
  .App iframe {
    width: 100%;
    height: 50vw;
  }
}

/* phone */
@media only screen and (max-width: 500px) {
  .App .wrapper {
    margin-top: 40px;
  }

  .App .text-3 {
    margin-top: 40px;
  }

  .App .header-container h1 {
    padding-left: 10px; padding-right: 10px;
    letter-spacing: 2px;
    font-size: 29px;
    text-align: center;
  }
  
  .App .header-container h2 {
    padding-left: 10px; padding-right: 10px;
    font-size: 17px;
    text-align: center;
  }

  .App .header-container {
    height: 200px;
  }

  .App .header-container img {
    height: 200px;
  }

  .App .zumVPN {
    width: 100%;
    height: 44px;
  }
}



body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  font-family: 'Montserrat', sans-serif;
  color: #3E3E3E;
  padding: 0;
}

button {
  padding: 0;
	cursor: pointer;
	text-decoration: none;
	border: none;
	outline: none;
	-webkit-tap-highlight-color: transparent;
}

p {
  margin: 0;
}

/* @import url('https://fonts.googleapis.com/css?family=Montserrat:500,600,700,800&display=swap'); */
/* @import url('https://fonts.googleapis.com/css?family=Raleway:500,600,700,800&display=swap'); */
@import url('https://fonts.googleapis.com/css?family=Heebo:400,500,700,800&display=swap');
